import React from "react";

const HeaderSection = () => {
	return (
		<section className="bg-gradient-to-br from-darkPurple to-gray-900 text-white flex items-center justify-center h-screen">
			<div className="mx-auto max-w-3xl text-center bg-accentPurple text-white rounded-lg p-8 mx-[30px]">
				<h1 className="bg-pastelPink bg-clip-text text-3xl font-extrabold text-transparent sm:text-7xl">
					Xpress
					<br />
					<span className="pt-4 sm:text-5xl  sm:block">
						Heels Dance Classes
					</span>
				</h1>

				<p className="mx-auto mt-4 max-w-xl text-whitePink sm:text-xl/relaxed">
					Begin your journey into the world of heels dance with Xpress Heels
					Dance Classes.
				</p>

				<div className="mt-8 flex flex-wrap justify-center gap-4">
					<a
						className="block text-pastelPink font-bold ease-in-out duration-500 w-full rounded border border-pastelPink px-12 py-3 text-sm font-medium  hover:bg-pastelPink hover:text-darkPurple focus:outline-none focus:ring active:bg-accentPink sm:w-auto"
						href="/about"
					>
						Learn More
					</a>
				</div>
			</div>
		</section>
	);
};

export default HeaderSection;
