import HeaderSection from "../components/HeaderSection";
import Carousel from "../components/Carousel";

const HomePage = () => {
	return (
		<section className="scroll-smooth">
			<HeaderSection />
			<Carousel />
		</section>
	);
};

export default HomePage;
