import React from "react";
import Gallery1 from "../assets/images/gallery1.jpg";
import Gallery2 from "../assets/images/gallery2.jpg";
import Gallery3 from "../assets/images/gallery3.jpg";
import Gallery4 from "../assets/images/gallery4.jpg";
import Gallery5 from "../assets/images/gallery5.jpg";
import Gallery6 from "../assets/images/gallery6.jpg";
import Gallery7 from "../assets/images/gallery7.jpg";
import Gallery8 from "../assets/images/gallery8.jpg";

const Carousel = () => {
	return (
		<div className="bg-whitePink p-[10px]">
			<div className="grid grid-cols-2 md:grid-cols-4 gap-4">
				<div className="grid gap-4">
					<div className="m-4 max-w-md overflow-hidden rounded-lg">
						<img
							className="h-auto max-w-full rounded-lg hover:scale-105 transition-all duration-700 cursor-pointer"
							src={Gallery1}
							alt=""
						/>
					</div>
					<div className="m-4 max-w-md overflow-hidden rounded-lg">
						<img
							className="h-auto max-w-full rounded-lg hover:scale-105 transition-all duration-500 cursor-pointer"
							src={Gallery8}
							alt=""
						/>
					</div>
				</div>
				<div className="grid gap-4">
					<div className="m-4 max-w-md overflow-hidden rounded-lg">
						<img
							className="h-auto max-w-full rounded-lg hover:scale-105 transition-all duration-500 cursor-pointer"
							src={Gallery2}
							alt=""
						/>
					</div>
					<div className="m-4 max-w-md overflow-hidden rounded-lg">
						<img
							className="h-auto max-w-full rounded-lg hover:scale-105 transition-all duration-500 cursor-pointer"
							src={Gallery7}
							alt=""
						/>
					</div>
				</div>
				<div className="grid gap-4">
					<div className="m-4 max-w-md overflow-hidden rounded-lg">
						<img
							className="h-auto max-w-full rounded-lg hover:scale-105 transition-all duration-500 cursor-pointer"
							src={Gallery3}
							alt=""
						/>
					</div>
					<div className="m-4 max-w-md overflow-hidden rounded-lg">
						<img
							className="h-auto max-w-full rounded-lg hover:scale-105 transition-all duration-500 cursor-pointer"
							src={Gallery6}
							alt=""
						/>
					</div>
				</div>
				<div className="grid gap-4">
					<div className="m-4 max-w-md overflow-hidden rounded-lg">
						<img
							className="h-auto max-w-full rounded-lg hover:scale-105 transition-all duration-500 cursor-pointer"
							src={Gallery4}
							alt=""
						/>
					</div>
					<div className="m-4 max-w-md overflow-hidden rounded-lg">
						<img
							className="h-auto max-w-full rounded-lg hover:scale-105 transition-all duration-500 cursor-pointer"
							src={Gallery5}
							alt=""
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Carousel;
